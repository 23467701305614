<template>
  <div class="realEstatePanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('Generate contract') }}
          </h4>
        </div>
        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">

          <!-- Field: First name -->
          <b-col cols="12" md="3" lg="3">
            <b-form-group :label="msg('Export contract to channels')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Client')" rules="required">
                <b-form-checkbox v-model="contract.hostawayApi" name="check-button" switch>
                  {{ contract.hostawayApi ? msg('Yes') : msg('No') }}
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Field: First name -->
          <b-col cols="12" md="9" lg="9">
            <b-form-group :label="msg('Paid')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Paid')" rules="required">
                <b-form-checkbox v-model="contract.paid" name="check-button" switch>
                  {{ contract.paid ? msg('Yes') : msg('No') }}
                </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
  <!-- Field: First name -->
          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Client Id')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Client')" rules="required">
                <client-picker v-model="contract.clientId"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Property Id')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Property')" rules="required">
                <real-estate-picker v-model="contract.propertyId"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Start')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Start date')" rules="required">
                <calendar-picker v-model="contract.bnbStyleRent.start"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('End')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('End date')" rules="required">
                <calendar-picker v-model="contract.bnbStyleRent.end"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Price')+' (Bsp: 350.60)'" label-for="building-company">
              <validation-provider #default="{ errors }" :name="msg('Price')" rules="required">
              <b-form-input id="realEstate-sell-price" type="number"
                            v-model="contract.bnbStyleRent.totalPayment"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="2">
            <b-form-group :label="msg('Usage')" label-for="realEstate-type">
              <validation-provider #default="{ errors }" :name="msg('Usage')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="contract.use"
                    :options="[
                      {value:'PRIVATE',label:msg('Private use')},
                      {value:'COMMERCIAL',label:msg('Commercial use')}]"
                    label="label"
                    :reduce="(status) => status.value"
                    class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="2">
            <b-form-group :label="msg('Number of guests')" label-for="realEstate-type">
              <validation-provider #default="{ errors }" :name="msg('Number of guests')" rules="required">
                <b-form-input id="realEstate-sell-price" type="number"
                              v-model="contract.numberOfGuests"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="3">
            <b-form-group :label="msg('Platform')" label-for="realEstate-type">
              <validation-provider #default="{ errors }" :name="msg('Platform')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="contract.bnbStyleRent.platform"
                    :options="[
                      {value:'Booking',label:msg('Booking')},
                      {value:'Airbnb',label:msg('Airbnb')},
                      {value:'Treasure Home',label:msg('Treasure Home')},
                      {value:'Expedia',label:msg('Expedia')},
                      {value:'Vrbo',label:msg('Vrbo')},
                      {value:'Agoda',label:msg('Agoda')},
                      {value:'Sonstige Plattform',label:msg('Sonstige Plattform')}
                      ]"
                    label="label"
                    :reduce="(status) => status.value"
                    class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-form-group :label="msg('Language')" label-for="realEstate-type">
              <validation-provider #default="{ errors }" :name="msg('Language')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="contract.language"
                    :options="languageOptions"
                    label="name"
                    :reduce="option => option.id"
                    class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        </b-row>

      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import ClientPicker from "@/components/components/picker/clientPicker";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker";

const initContract = () => (
    {
      hostawayApi: true, name: '', propertyId: null, clientId: null,
      bnbStyleRent: {
        platform: null, active: true,
        start: null, end: null, totalPayment: 0.0, oneTimePayment: false,
        totalPaymentDiscount: null, totalServiceFee: null, totalPriceHandlingFee: null,
      },
      type: null,
    }
);

export default {
  name: 'treasureContractRentPanel',
  components: {RealEstatePicker, ClientPicker},
  data() {
    return {
      loaded: false,
      error: null,
      contractId: 0,
      contract: initContract(),
      languageOptions: [
        // Define your language options here, excluding Arabic
        {id: 'EN', name: 'English'},
        {id: 'ES', name: 'Spanish'},
        {id: 'FR', name: 'French'},
        {id: 'DE', name: 'German'},
        {id: 'IT', name: 'Italian'},
        {id: 'PT', name: 'Portuguese'},
        {id: 'RU', name: 'Russian'},
        {id: 'CN', name: 'Chinese'},
        {id: 'JP', name: 'Japanese'}
        // Add more languages as needed
      ],
      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),
  },
  created() {
    this.onLoad()
    this.$root.$on('crm::submitContract', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitContract', this.listeners.submit)
  },
  methods: {
    ...mapActions('realEstateContracts', {_createContract: 'createContract',}),
    onLoad() {
      this.contract = initContract();
      this.loaded = true
    },
    setData(propertyId, start, end){
      this.contract.propertyId = propertyId
      this.contract.bnbStyleRent.start = start
      this.contract.bnbStyleRent.end = end
      this.loaded = true
    },
    createContract() {
      let $this = this
      if (this.contract.paid == null)
        this.contract.paid = false
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._createContract(this.contract)
            .then(
                (id) => {

                  $this.contractId = id
                  $this.$emit('submitted', {
                    status: true,
                    server: id,
                    id
                  })
                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
            .finally(() => {
              if ($this.contractId != null && $this.contractId !== 0&& $this.contractId !== 'success'&& !$this.contractId.contains('Error'))
                this.$router.push(`/real_estate/contracts/rent/${$this.contractId}`);

            })
      })
    },

    validate(field) {
      return this.$v.contract[field] != null && this.$v.contract[field].$error === true
    }
    ,
    submit() {
      return this.createContract()
    }

  },

}
</script>


