var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"realEstatePanel"},[(_vm.loaded)?_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"refFormObserver"},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"PackageIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" "+_vm._s(_vm.msg('Generate contract'))+" ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"3","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Export contract to channels'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.contract.hostawayApi),callback:function ($$v) {_vm.$set(_vm.contract, "hostawayApi", $$v)},expression:"contract.hostawayApi"}},[_vm._v(" "+_vm._s(_vm.contract.hostawayApi ? _vm.msg('Yes') : _vm.msg('No'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3884469018)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"9","lg":"9"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Paid'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Paid'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.contract.paid),callback:function ($$v) {_vm.$set(_vm.contract, "paid", $$v)},expression:"contract.paid"}},[_vm._v(" "+_vm._s(_vm.contract.paid ? _vm.msg('Yes') : _vm.msg('No'))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2264948922)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Client Id'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('client-picker',{model:{value:(_vm.contract.clientId),callback:function ($$v) {_vm.$set(_vm.contract, "clientId", $$v)},expression:"contract.clientId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2975914359)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Property Id'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Property'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('real-estate-picker',{model:{value:(_vm.contract.propertyId),callback:function ($$v) {_vm.$set(_vm.contract, "propertyId", $$v)},expression:"contract.propertyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,211231605)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Start'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Start date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('calendar-picker',{model:{value:(_vm.contract.bnbStyleRent.start),callback:function ($$v) {_vm.$set(_vm.contract.bnbStyleRent, "start", $$v)},expression:"contract.bnbStyleRent.start"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1378029170)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('End'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('End date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('calendar-picker',{model:{value:(_vm.contract.bnbStyleRent.end),callback:function ($$v) {_vm.$set(_vm.contract.bnbStyleRent, "end", $$v)},expression:"contract.bnbStyleRent.end"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3367644317)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Price')+' (Bsp: 350.60)',"label-for":"building-company"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Price'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-sell-price","type":"number"},model:{value:(_vm.contract.bnbStyleRent.totalPayment),callback:function ($$v) {_vm.$set(_vm.contract.bnbStyleRent, "totalPayment", $$v)},expression:"contract.bnbStyleRent.totalPayment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4140822120)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Usage'),"label-for":"realEstate-type"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Usage'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":[
                    {value:'PRIVATE',label:_vm.msg('Private use')},
                    {value:'COMMERCIAL',label:_vm.msg('Commercial use')}],"label":"label","reduce":function (status) { return status.value; }},model:{value:(_vm.contract.use),callback:function ($$v) {_vm.$set(_vm.contract, "use", $$v)},expression:"contract.use"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,901673729)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Number of guests'),"label-for":"realEstate-type"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Number of guests'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"realEstate-sell-price","type":"number"},model:{value:(_vm.contract.numberOfGuests),callback:function ($$v) {_vm.$set(_vm.contract, "numberOfGuests", $$v)},expression:"contract.numberOfGuests"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,287148835)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Platform'),"label-for":"realEstate-type"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Platform'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":[
                    {value:'Booking',label:_vm.msg('Booking')},
                    {value:'Airbnb',label:_vm.msg('Airbnb')},
                    {value:'Treasure Home',label:_vm.msg('Treasure Home')},
                    {value:'Expedia',label:_vm.msg('Expedia')},
                    {value:'Vrbo',label:_vm.msg('Vrbo')},
                    {value:'Agoda',label:_vm.msg('Agoda')},
                    {value:'Sonstige Plattform',label:_vm.msg('Sonstige Plattform')}
                    ],"label":"label","reduce":function (status) { return status.value; }},model:{value:(_vm.contract.bnbStyleRent.platform),callback:function ($$v) {_vm.$set(_vm.contract.bnbStyleRent, "platform", $$v)},expression:"contract.bnbStyleRent.platform"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2512260180)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"2"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Language'),"label-for":"realEstate-type"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Language'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":_vm.languageOptions,"label":"name","reduce":function (option) { return option.id; }},model:{value:(_vm.contract.language),callback:function ($$v) {_vm.$set(_vm.contract, "language", $$v)},expression:"contract.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,479381758)})],1)],1)],1)],1)],1):(this.error != null)?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"fas fa-exclamation-triangle mr-3"}),_c('strong',[_vm._v(_vm._s(_vm.error))])])],1):_c('div',[_c('div',{staticStyle:{"min-height":"300px","text-align":"center"}},[_c('div',{staticStyle:{"margin-top":"20%"}},[_c('b-spinner',{attrs:{"label":"Spinning"}}),_vm._v(" "+_vm._s(_vm.msg('loading'))+" ")],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }