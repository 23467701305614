<template>
  <div class="realEstatePanel">
    <div class="mt-2" v-if="loaded">
      <validation-observer ref="refFormObserver">
        <!-- Header: Personal Info -->
        <div class="d-flex">
          <feather-icon icon="PackageIcon" size="19"/>
          <h4 class="mb-0 ml-50">
            {{ msg('Generate contract') }}
          </h4>
        </div>
        <!-- Form: Personal Info Form -->
        <b-row class="mt-1">

          <!-- Field: First name -->
          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Client Id')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Client')" rules="required">
                <client-picker v-model="contract.clientId"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Property Id')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Property')" rules="required">
                <real-estate-picker v-model="contract.propertyId"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col cols="12" md="6" lg="4">
            <b-form-group :label="msg('Usage')" label-for="realEstate-type">
              <validation-provider #default="{ errors }" :name="msg('Usage')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="contract.use"
                    :options="[
                      {value:'PRIVATE',label:msg('Private use')},
                      {value:'COMMERCIAL',label:msg('Commercial use')}]"
                    label="label"
                    :reduce="(status) => status.value"
                    class="w-100"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>


          <b-col cols="3" md="3" lg="3">
            <b-form-group :label="msg('Language')" label-for="realEstate-name">
              <validation-provider #default="{ errors }" :name="msg('Language')" rules="required">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    :clearable="false"
                    v-model="contract.language"
                    :options="[
            { id: 'EN', name: 'English' },
            { id: 'ES', name: 'Spanish' },
            { id: 'FR', name: 'French' },
            { id: 'DE', name: 'German' },
            { id: 'IT', name: 'Italian' },
            { id: 'PT', name: 'Portuguese' },
            { id: 'RU', name: 'Russian' },
            { id: 'CN', name: 'Chinese' },
            { id: 'JP', name: 'Japanese' } ]"
                    class="w-100"
                    label="name"
                    :reduce="(branch) => branch.id"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
    </div>
    <div v-else-if="this.error != null">
      <b-alert show variant="danger"><i class="fas fa-exclamation-triangle mr-3"></i>
        <strong>{{ error }}</strong>
      </b-alert>
    </div>
    <div v-else>
      <div style="min-height: 300px;text-align: center">
        <div style="margin-top: 20%">
          <b-spinner label="Spinning"/>
          {{ msg('loading') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import ClientPicker from "@/components/components/picker/clientPicker";
import RealEstatePicker from "@/components/components/picker/realEstatesPicker";

export default {
  name: 'contractRentPanel',
  components: {RealEstatePicker, ClientPicker},
  data() {
    return {
      loaded: false,
      error: null,
      contractId: 0,
      contract: {
        name: '',
        type: null,
      },

      //Listeners
      listeners: {
        submit: () => {
        }
      }
    }
  },
  watch: {
    id: function () { // watch it
      this.onLoad()
      this.error = null
    }
  },
  computed: {
    ...mapGetters('realEstate', ['getAllTypes']),

  },
  created() {

    this.onLoad()
    this.$root.$on('crm::submitContract', (this.listeners.submit = () => {
      this.submit()
    }))
  },
  beforeDestroy() {
    this.$root.$off('crm::submitContract', this.listeners.submit)
  },
  methods: {
    ...mapActions('realEstateContracts', {
      _createContract: 'createContract',
    }),
    onLoad() {
      this.loaded = true

    }
    ,
    createContract() {
      let $this = this
      this.$refs['refFormObserver'].validate().then(valid => {
        if (!valid)
          return;
        $this._createContract(this.contract)
            .then(
                (id) => {

                  $this.contractId = id
                  $this.$emit('submitted', {
                    status: true,
                    server: id,
                    id
                  })
                },
                (a) => $this.$emit('submitted', {
                  status: false,
                  server: a
                })
            )
            .finally(() => {
              if ($this.contractId != null && $this.contractId !== 0)
                this.$router.push(`/real_estate/contracts/rent/${$this.contractId}`);

            })
      })
    },

    validate(field) {
      return this.$v.contract[field] != null && this.$v.contract[field].$error === true
    }
    ,
    submit() {
      return this.createContract()
    }

  },

}
</script>


