var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"realEstatePanel"},[(_vm.loaded)?_c('div',{staticClass:"mt-2"},[_c('validation-observer',{ref:"refFormObserver"},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{attrs:{"icon":"PackageIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50"},[_vm._v(" "+_vm._s(_vm.msg('Generate contract'))+" ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Client Id'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('client-picker',{model:{value:(_vm.contract.clientId),callback:function ($$v) {_vm.$set(_vm.contract, "clientId", $$v)},expression:"contract.clientId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2975914359)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Property Id'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Property'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('real-estate-picker',{model:{value:(_vm.contract.propertyId),callback:function ($$v) {_vm.$set(_vm.contract, "propertyId", $$v)},expression:"contract.propertyId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,211231605)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Usage'),"label-for":"realEstate-type"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Usage'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"options":[
                    {value:'PRIVATE',label:_vm.msg('Private use')},
                    {value:'COMMERCIAL',label:_vm.msg('Commercial use')}],"label":"label","reduce":function (status) { return status.value; }},model:{value:(_vm.contract.use),callback:function ($$v) {_vm.$set(_vm.contract, "use", $$v)},expression:"contract.use"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,901673729)})],1)],1),_c('b-col',{attrs:{"cols":"3","md":"3","lg":"3"}},[_c('b-form-group',{attrs:{"label":_vm.msg('Language'),"label-for":"realEstate-name"}},[_c('validation-provider',{attrs:{"name":_vm.msg('Language'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"dir":_vm.isRTL ? 'rtl' : 'ltr',"clearable":false,"options":[
          { id: 'EN', name: 'English' },
          { id: 'ES', name: 'Spanish' },
          { id: 'FR', name: 'French' },
          { id: 'DE', name: 'German' },
          { id: 'IT', name: 'Italian' },
          { id: 'PT', name: 'Portuguese' },
          { id: 'RU', name: 'Russian' },
          { id: 'CN', name: 'Chinese' },
          { id: 'JP', name: 'Japanese' } ],"label":"name","reduce":function (branch) { return branch.id; }},model:{value:(_vm.contract.language),callback:function ($$v) {_vm.$set(_vm.contract, "language", $$v)},expression:"contract.language"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1426747616)})],1)],1)],1)],1)],1):(this.error != null)?_c('div',[_c('b-alert',{attrs:{"show":"","variant":"danger"}},[_c('i',{staticClass:"fas fa-exclamation-triangle mr-3"}),_c('strong',[_vm._v(_vm._s(_vm.error))])])],1):_c('div',[_c('div',{staticStyle:{"min-height":"300px","text-align":"center"}},[_c('div',{staticStyle:{"margin-top":"20%"}},[_c('b-spinner',{attrs:{"label":"Spinning"}}),_vm._v(" "+_vm._s(_vm.msg('loading'))+" ")],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }